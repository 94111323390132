
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import vselect3 from "vselect3";
import api from "@/services/api.service";

const GenericTableStore = namespace("GenericTable");

@Component({
	components: {
		VuePerfectScrollbar: VuePerfectScrollbar,
		"v-select3": vselect3
	}
})
export default class ApplianceList extends Vue {
	$refs!: {};
	@GenericTableStore.Getter getModalRow: any;

	@Prop({type:Boolean, default: false})
	private readonly: boolean;

	@Prop()
	public value: any;
	public applianceList: any[] = [];
	public selectedAppliance: any = {};

	public async mounted() {
		this.applianceList = await api.getApplianceList();
		if (this.value) {
			this.updateFromValue();
		}
	}

	@Watch("value")
	valueChanged(value: string) {
		this.updateFromValue();
	}

	@Watch("selectedAppliance", { deep: true })
	public updateAppliance(selectedAppliance): void {
		if (selectedAppliance) {
			this.$emit("input", selectedAppliance.applianceId);
		} else {
			this.$emit("input", null);
		}
	}


	private async updateFromValue() {
		this.selectedAppliance = this.applianceList.find(a => a.applianceId == this.value);
	}
}

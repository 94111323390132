
import { Component, Prop, Vue } from "vue-property-decorator";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { Getter, namespace } from "vuex-class";
import { ServerDetails } from "@/store/devices/types";
import api from "@/services/api.service";
import { SessionResource } from "@/store/sessions/types";
import AreaTreeSelect from "@/components/form/AreaTreeSelect.vue";
import { Constants } from "@/types/sv-data/devices/Constants";

export interface OpsLink {
	id: number,
	groupId: number,
	title: string,
	isOnline: boolean,
	devices: OpsLinkDevice [],
	isLoading: boolean
}

export interface OpsLinkDevice {
	id: number,
	host: string,
	type: string,
	username: string,
	tempUsername: string,
	password: string,
	tempPassword: string,
	cameraStreams: OpsCameraStream[],
	isEditingCreds: boolean,
	isLoading: boolean
}

interface OpsCameraStream {
	streamInfo: StreamInfo,
	groupId: number,
	isEditing: boolean,
	isAdding: boolean
}

interface StreamInfo {
	title: string,
	cameraName: string,
	encoding: string,
	width: number,
	height: number,
	port: number,
	uriPath?: string
}

const Eventqueue = namespace("eventqueue");
const Session = namespace("sessions");

@Component({
	components: {
		"area-tree-select": AreaTreeSelect,
		"vue-perfect-scrollbar": VuePerfectScrollbar,
	}
})
export default class OpsLinkCameraImport extends Vue {

	@Session.Action updateSession: any;
	@Session.Getter getSession: any;
	@Getter getUserTenantGroupId: number;

	@Prop() public value: boolean;

	private availableOpsLinks: OpsLink[] = [];
	private availableServers: ServerDetails[] = [];
	private deviceServiceAddress: string = "";
	private isLoading: boolean = false;
	private isAdding: boolean = false;
	private totalToAdd: number = 0;
	private totalAdded: number = 0;
	private primaryGroupId: number = 0;

	private async mounted() {
		this.deviceServiceAddress = api.getDeviceServiceAddress();
		this.primaryGroupId = this.getUserTenantGroupId;
	}

	private async onOpen() {
		this.isLoading = true;

		this.availableServers = (await api.getServers()).data;

		this.availableOpsLinks = (await api.getApplianceList()).map(x => {
			let result: OpsLink = {
				id: x.applianceId,
				groupId: x.groupId,
				title: x.title,
				isOnline: x.isOnline,
				isLoading: false,
				devices: []
			};
			return result;
		});

		this.isLoading = false;

		// if there's only 1 available ops link, start loading it, saves a click.
		if (this.availableOpsLinks.length === 1 && this.availableOpsLinks[0].isOnline) {
			await this.loadOpsLink(this.availableOpsLinks[0]);
		}
	}

	private close() {
		this.totalAdded = 0;
		this.$emit("input", false);
	}

	private async add() {
		const DefaultInputNumber = 1;
		const CameraDeviceType = 1;
		const DefaultCameraSettings = Constants.DEFAULT_CAMERA_SETTINGS_META;
		const RtspServerType = 542;

		this.isAdding = true;
		this.totalAdded = 0;
		this.totalToAdd = this.totalDevicesToAdd();
		for (let opsLink of this.availableOpsLinks) {
			for (let device of opsLink.devices) {
				for (let cameraStream of device.cameraStreams) {
					// has this stream been mapped to a group?
					if (cameraStream.groupId === 0) {
						// if not skip.
						continue;
					}

					let serverId = 0;

					// does a 'server' already exist with this device host, group ID & camera name
					let existingServer = this.availableServers.find(s =>
						s.host === device.host &&
						s.groupID === cameraStream.groupId &&
						s.title === cameraStream.streamInfo.cameraName
					);
					if (existingServer) {
						// if so use the existing server id.
						serverId = existingServer.serverID;
					}
					else {
						// if not add it and get the new server id.
						serverId = await api.addServer({
							serverID: undefined,
							serverTypeID: RtspServerType,
							groupID: cameraStream.groupId,
							title: cameraStream.streamInfo.cameraName,
							host: device.host,
							port: cameraStream.streamInfo.port,
							username: device.username,
							password: device.password,
							extraValue: cameraStream.streamInfo.uriPath,
							applianceId: opsLink.id
						});

						this.availableServers = (await api.getServers()).data;
					}

					// map the camera stream to a new device.
					let newDevice = {
						serverID: serverId,
						deviceTypeId: CameraDeviceType,
						input1: DefaultInputNumber,
						title: cameraStream.streamInfo.cameraName,
						groupID: cameraStream.groupId,
						settingsMeta: DefaultCameraSettings
					};

					await api.addDevice(newDevice);

					this.totalAdded += 1;
					cameraStream.isAdding = false;
					cameraStream.groupId = 0;
				}
			}
		}
		this.isAdding = false;
		this.$emit("import-complete");
	}

	private async loadOpsLink(opsLink: OpsLink) {
		opsLink.isLoading = true;
		opsLink.devices = [];

		let opsLinkConnectionErrorOptions = {
			type: "error",
			title: "Ops Link Connection issue",
			text:
				"Unable to establish secure connection to your Ops Link device - please try again later, or contact support if the problem persists.",
			duration: 7000
		};
		let componentContext = this;

		// update the session for use with the Device Service and use it
		await this.updateSession({ resourceId: SessionResource.DeviceServiceSession });
		let authSession = this.getSession(SessionResource.DeviceServiceSession);

		let netDetectEndpoint = encodeURI(`${this.deviceServiceAddress}/devices/netdetect?applianceId=${opsLink.id}&auth=${authSession}`);
		let xhr = new XMLHttpRequest();
		xhr.open("GET", netDetectEndpoint);
		xhr.onload = function() {
			if (this.status == 200) {
				let devices = JSON.parse(xhr.response);
				const devicePort = 554;
				devices.forEach(d => {
					if (d.ProbeMatches || d.RTSP) {
						let isRTSPOnly = d.RTSP && !d.ProbeMatches;
						let deviceType = isRTSPOnly ? "RTSP" : "ONVIF";
						let deviceId = opsLink.devices.length + 1;
						let rtspCameraStream: OpsCameraStream = {
							groupId: 0,
							isEditing: false,
							isAdding: false,
							streamInfo: {
								title: "",
								cameraName: ``,
								uriPath: "",
								width: 0,
								height: 0,
								encoding: "",
								port: devicePort
							}
						};

						opsLink.devices.push({
							id: deviceId,
							host: d.Host,
							type: deviceType,
							username: "",
							tempUsername: "",
							password: "",
							tempPassword: "",
							cameraStreams: isRTSPOnly ? [ rtspCameraStream ] : [],
							isEditingCreds: false,
							isLoading: false
						});
					}
				});
			}
			else {
				componentContext.$notify(opsLinkConnectionErrorOptions);
			}

			opsLink.isLoading = false;
		};

		xhr.onerror = function() {
			componentContext.$notify(opsLinkConnectionErrorOptions);
			opsLink.isLoading = false;
		};
		xhr.send();
	}

	private async loadOnvifDeviceConfiguration(opsLink: OpsLink, device: OpsLinkDevice) {
		const rtspPort = 80;
		let componentContext = this;

		device.isLoading = true;
		device.cameraStreams = [];

		// update the session for use with the Device Service and use it
		await this.updateSession({ resourceId: SessionResource.DeviceServiceSession });
		let authSession = this.getSession(SessionResource.DeviceServiceSession);

		let getConfigEndpoint = encodeURI(`${this.deviceServiceAddress}/devices/config?` +
						`auth=${authSession}`+
						`&file=Onvif\\DevOnvif.dll&type=DevOnvif.OnvifDevice` +
						`&host=${device.host}&port=${rtspPort}` +
						`&user=${device.username}&password=${device.password}` +
						`&applianceId=${opsLink.id}` +
						`&input1=&urlTemplate=&prefValue=&extra=&input2=&deviceExtra=&site=&format=json`);

		const xhr = new XMLHttpRequest();
		xhr.open("GET", getConfigEndpoint);
		xhr.onload = function() {
			if (this.status == 200) {
				let configResult = JSON.parse(xhr.response)[0];

				let deviceTitle = configResult.Name;
				configResult.Cameras.forEach(c => {
					let width = 0;
					let widthFeature = c.Features.find(f => f.Name === "Width");
					if (widthFeature) {
						width = parseInt(widthFeature.Value);
					}

					let height = 0;
					let heightFeature = c.Features.find(f => f.Name === "Height");
					if (heightFeature) {
						height = parseInt(heightFeature.Value);
					}

					let encoding = "";
					let encodingFeature = c.Features.find(f => f.Name === "Encoding");
					if (encodingFeature) {
						encoding = encodingFeature.Value;
					}

					let canUseRTSPFeature = c.Features.find(f => f.Name === "UriIsVariable");
					if (canUseRTSPFeature && canUseRTSPFeature.Value === "False") {
						let rtspUriFeature = c.Features.find(f => f.Name === "Uri");
						if (rtspUriFeature) {
							let rtspUrl = rtspUriFeature.Value.split("rtsp://")?.[1];
							let port = rtspUrl?.split(":")[1]?.split("/")[0] || 554;
							let urlPath = rtspUrl?.split(/\/(.*)/)?.[1] || '';

							device.cameraStreams.push({
								streamInfo: {
									title: c.ExtraValue,
									width,
									height,
									encoding,
									port: parseInt(port),
									uriPath: urlPath,
									cameraName: `${deviceTitle} ${c.ExtraValue}`
								},
								groupId: 0,
								isEditing: false,
								isAdding: false,
							});
						}
					} else {
						device.cameraStreams.push({
							streamInfo: {
								title: c.ExtraValue,
								width,
								height,
								encoding,
								cameraName: `${deviceTitle} ${c.ExtraValue}`,
								port: 554
							},
							groupId: 0,
							isEditing: false,
							isAdding: false,
						});
					}
				});
			} else {
				if (xhr.statusText.includes("DeviceConfigurationGet()")) {
					componentContext.$notify({
						type: "error",
						title: "Could not get device configuration",
						text:
							"Please check the device credentials and try again, or contact support if the problem persists."
					});
				}
				else {
					componentContext.$notify({
						type: "error",
						title: "Could not get device configuration",
						text:
							"Please try again, or contact support if the problem persists."
					});
				}

				device.cameraStreams = [];
			}
			device.isLoading = false;
		};
		xhr.onerror = function() {
			device.cameraStreams = [];
			device.isLoading = false;
			componentContext.$notify({
				type: "error",
				title: "Could not get device configuration",
				text:
					"Please try again, or contact support if the problem persists."
			});
		};
		xhr.send();
	}

	private getRowCountForOpsLink(opsLink: OpsLink): number {
		let total = 0;
		opsLink.devices.forEach(d => {
			// if there are no camera streams, increase the row count by 1.
			if (d.cameraStreams.length === 0) {
				total += 1;
			}
			else {
				// if the device type is RTSP increase the row count by 1.
				if (d.type === 'RTSP') {
					total += 1;
				}
				// otherwise increase the row count by the number of camera streams.
				else {
					total += d.cameraStreams.length;
				}
			}
		});
		return total;
	}

	private cancelSetDevicePassword(device: OpsLinkDevice) {
		device.tempUsername = device.username;
		device.tempPassword = device.password;
		device.isEditingCreds = false;
	}

	private setDevicePassword(opsLink: OpsLink, device: OpsLinkDevice) {
		device.username = device.tempUsername;
		device.password = device.tempPassword;
		device.isEditingCreds = false;
		if (device.type === "ONVIF") {
			this.loadOnvifDeviceConfiguration(opsLink, device);
		}
	}

	private get canAdd() {
		if (this.isAdding) {
			return false;
		}

		let hasCamerasToAdd = false
		// if no cameras have been given a name, we can't add.
		for (let opsLink of this.availableOpsLinks) {
			for (let device of opsLink.devices) {
				for (let cameraStream of device.cameraStreams.filter(cs => cs.isAdding && cs.groupId !== 0)) {
					if (cameraStream.streamInfo.cameraName === '') {
						return false;
					}

					hasCamerasToAdd = true;
				}
			}
		}

		return hasCamerasToAdd;
	}

	private totalDevicesToAdd() {
		let total = 0;
		this.availableOpsLinks.forEach(ol => {
			return ol.devices.forEach(d => {
				total += d.cameraStreams.filter(cs => cs.groupId !== 0).length;
			});
		});
		return total;
	}

	private calculateTableHeight() {
		let result = document.body.clientHeight / 100 * 65;
		return `${result}px`;
	}

	private areaWarnings(deviceUsername, cameraName) {
		if (deviceUsername === '' && cameraName === ''){
			return "Device credentials and camera name are required."
		}

		if (deviceUsername === '') {
			return "Device credentials are required."
		}

		if (cameraName === '') {
			return "Camera name is required."
		}

		return "";
	}

	/*
	Method to assign the cameraStream with the primary group id as an initial value when add to area checkbox is checked. Set to 0 if unchecked.
	*/
	private async cameraStreamAddedToArea(cameraStream: OpsCameraStream) {
		if (cameraStream.isAdding) {
			if (!cameraStream.groupId) {
				/* Wait for next tick to give the components' value watcher enough time to detect a value change.
				If we happen to set the value back to what it was before being deselected in the same tick, the watcher doesn't detect a change.
				*/
				await this.$nextTick();
			}
			cameraStream.groupId = this.primaryGroupId;
		}
		else {
			cameraStream.groupId = 0;
		}
	}

	/*
	Helper method invoked when @input is emmitted on the camera stream area tree select.
	If groupID is invalid, use the initial value.
	*/
	private handleCameraStreamGroupChange(cameraStream: OpsCameraStream) {
		// Deselection sets groupID = null, set to initial.
		if (!cameraStream.groupId) {
			this.cameraStreamAddedToArea(cameraStream);
		}
	}
}
